const eventsToIgnore = [
    'pointermove',
    'mouseout',
    'mouseover',
    'mousemove',
    'scroll',
    'resize',
    'wheel',
    'contextmenu',
    'touchmove',
    'animationend',
    'requestAnimationFrame'
];
// patching these event with zone causes a major slowdown in map performance.
// Source: https://stackoverflow.com/a/58533365/3320297
(window as any).__zone_symbol__BLACK_LISTED_EVENTS = eventsToIgnore;
// this prevents openlayers from triggering change detection
// This is the "new" way of doing it:
// https://github.com/angular/angular/blob/11.2.x/packages/zone.js/lib/browser/api-util.ts#L27
(window as any).__zone_symbol__UNPATCHED_EVENTS = eventsToIgnore;
